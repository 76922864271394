<template>
  <v-app>
    <page-view />
  </v-app>
</template>

<script>
  export default {
    name: 'PageLayout',

    components: { PageView: () => import('./View') },

    created() {
      this.$store.dispatch('auth/fetchMe')
        .then(() => {
          this.$router.push('/');
        })
        .catch(() => {});
    },
  }
</script>
